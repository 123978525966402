.panel {
  background-color: #fff;
  padding: 20px;
  margin: 20px auto;
  border-radius: 20px;

  h3, h2 {
    margin: 0 -20px 20px -20px;
    padding: 0 20px 20px 20px;
    border-bottom: 1px solid $lightGray;
    font-weight: 500 !important;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }
}
