.loading {
  margin: 15px auto;
  img {
    text-align: center;
  }

  p {
    text-align: center;
    color: #909090;
  }
}
