input, textarea {
  min-width: 250px;
  padding: 10px;
}

input:focus{
  outline: none;
}

select {
  padding: 10px;
  margin: 0 10px 0 0;
}

label {
  font-size: 85%;
  color: $gray;
  display: block;
  padding: 5px 0;
}

span.readonly {
  padding: 10px;
  margin-right: 5px;
  font-size: 80%;
  border: 1px solid $gray;
  width: 85%;
  display: block;
}
