$black: #282c34;
$blue: #008ff7;
$turquoise: #47C4E1;
$white: #ffffff;
$gray: #919191;
$mediumGray: #CCCACD;
$lightGray: #E1E1E1;
$red: #F28282;
$deepRed: #952c2c;
$orange: #ee9240;

body {
  background-color: #faf9f9; 
}

.App { 
  height: 100vh;
  min-height: 100%;
  position: relative;

  section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
  }

  ul {
    list-style-type: none;
  }

  .icon-color {
    fill: rgb(178, 181, 178);
  }

  h2 {
    color: $black;
    font-weight: 400 !important;
  }

  // components
  @import './table';
  @import './forms';
  @import './errors';
  @import './panel';
  @import './navigation';
  @import './modal';
  @import './button';
  @import './loading';
  @import './list';
  @import './footer';
}

// pages
@import './signin';
@import './header';
@import './connectSafe';
@import './transaction';

