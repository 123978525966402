.transaction {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;

  table {
    flex: none;
    width: 100%;
  }

  p.data {
    word-wrap: break-word;
    max-width: 750px;
  }
}
