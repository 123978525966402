.App.signin {
  background-color: #aef7d8;
  background-image: linear-gradient(#0190f7,#aef7d8);
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;

  img {
    max-width: 200px;
    margin-top: 100px;
  }

  .logo {
    font-size: 28px;
    font-weight: 600 !important;
    margin-bottom: 15px;
  }

  .legal {
    font-size: 12px;
  }

  h2 {
    color: #fff;
  }
}
