ul.navigation {
  display: block;
  margin: 0 0 30px 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 10px;
    font-size: 18px;

    button, button:hover {
      background: none;
      font-size: 18px;
    }

    button.selected {
      border-bottom: 2px solid $blue;
    }
  }

  li.logout {
    float: right;

    button {
      background: none;
      font-size: 18px;
    }
  }
}
