section.error {
  position: fixed;
  bottom: 0;
  width: 100%;

  color: $white;
  background-color: $deepRed;
  padding: 5px 20px;
  margin-top: 25px;
  z-index: 1000;

  button {
    position: absolute;
    top: 5px;
    right: 10px;
    border: 1px solid $red;
    border-radius: 5px;
    background: none;
    color: $white;
  }
}