.connect {
  input {
    width: 600px;
  }

  h2 {
    font-size: 22px;

    button {
      float: right;
    }
  }
}
